import JoncoLogo from "../assets/Logo.png";
import { useState } from "react";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import JONCO from "../assets/Logo.png";

const NavbarPages = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const navigate = useNavigate();

  const openCloseMenu = () => {
    setMenuToggle(!menuToggle);
  };

  const loggingOut = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    navigate("/");
  };

  return (
    <nav className="primary-font text-lg subpixel-antialiased shadow-md shadow-gray-200">
      <div className="mx-auto max-w-7xl">
        <div className="mx-auto flex items-center justify-between py-4 px-4">
          <img
            className="w-36 rounded-md  p-2"
            src={JONCO}
            alt="Company Logo"
          />
          {/* Primary Nav */}
          <div className="hidden md:block ">
            {!localStorage.getItem("token") && (
              <NavLink
                className="primary-font px-3 font-bold text-gray-800 decoration-red-500 decoration-2 underline-offset-2 focus:underline md:px-6 lg:text-2xl"
                to="/"
              >
                Home
              </NavLink>
            )}
            {localStorage.getItem("token") &&
              localStorage.getItem("role") === "super_admin" && (
                <NavLink
                  className="primary-font px-3 font-bold text-gray-800 decoration-red-500 decoration-2 underline-offset-2 focus:underline md:px-6 lg:text-2xl"
                  to="/admins"
                >
                  Admins
                </NavLink>
              )}
            <NavLink
              className="primary-font px-3 font-bold text-gray-800 decoration-red-500 decoration-2 underline-offset-2 focus:underline md:px-6 lg:text-2xl"
              to={localStorage.getItem("token") ? "/services" : "/services"}
            >
              Services
            </NavLink>
            <NavLink
              className="primary-font px-3 font-bold text-gray-800 decoration-red-500 decoration-2 underline-offset-2 focus:underline md:px-6 lg:text-2xl"
              to={
                localStorage.getItem("token")
                  ? "/dashboard/projects"
                  : "/projects"
              }
            >
              Projects
            </NavLink>
            <NavLink
              className="primary-font px-3 font-bold text-gray-800 decoration-red-500 decoration-2 underline-offset-2 focus:underline md:px-6 lg:text-2xl"
              to="/clients"
            >
              Clients
            </NavLink>
          </div>
          {/* Secondary Nav */}
          <div className="flex">
            <NavLink
              className="hidden w-28 rounded-full bg-red-500 px-2 py-2 text-sm text-white shadow-sm shadow-red-800 transition-all hover:bg-red-400 md:flex md:items-center md:justify-center lg:w-36 lg:px-0 lg:text-lg"
              to="/contact"
            >
              Contact us
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="ml-1 inline h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </NavLink>
            {localStorage.getItem("token") && (
              <button
                onClick={loggingOut}
                className="ml-2 hidden text-sm text-black md:block lg:ml-5 lg:text-base"
              >
                Logout
              </button>
            )}
          </div>
          {/* Mobile Nav */}
          <div className="flex items-center justify-center md:hidden">
            <button onClick={openCloseMenu}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-10 w-10 text-gray-800 "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={menuToggle ? "md:hidden " : "hidden md:hidden"}>
        <div className="flex flex-col justify-center border bg-white p-3  ">
          {!localStorage.getItem("token") && (
            <NavLink
              to="/"
              className="text-md inline-block w-full rounded px-2 py-2 text-center hover:bg-red-400 hover:text-white"
            >
              Home
            </NavLink>
          )}
          {localStorage.getItem("token") &&
            localStorage.getItem("role") === "super_admin" && (
              <NavLink
                to="/admins"
                className="text-md inline-block w-full rounded px-2 py-2 text-center hover:bg-red-400 hover:text-white"
              >
                Admins
              </NavLink>
            )}
          <NavLink
            to={localStorage.getItem("token") ? "/services" : "/services"}
            className="text-md inline-block w-full rounded px-2 py-2 text-center hover:bg-red-400 hover:text-white"
            onClick={openCloseMenu}
          >
            Services
          </NavLink>
          <NavLink
            to={
              localStorage.getItem("token")
                ? "/dashboard/projects"
                : "/projects"
            }
            className="text-md inline-block w-full rounded px-2 py-2 text-center hover:bg-red-400 hover:text-white"
            onClick={openCloseMenu}
          >
            Projects
          </NavLink>
          <NavLink
            to="/clients"
            className="text-md inline-block w-full rounded px-2 py-2 text-center hover:bg-red-400 hover:text-white"
            onClick={openCloseMenu}
          >
            Clients
          </NavLink>
          <NavLink
            to="/contact"
            className="text-md inline-block w-full rounded px-2 py-2 text-center hover:bg-red-400 hover:text-white"
            onClick={openCloseMenu}
          >
            Contact us
          </NavLink>
          {localStorage.getItem("token") && (
            <button
              onClick={loggingOut}
              className="text-md inline-block w-full rounded px-2 py-2 text-center hover:bg-red-400 hover:text-white"
            >
              Logout
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavbarPages;
