import React, { useContext, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ErrorPopup from "../AlertPopup/ErrorPopup";
import checkUser from "../../../Dashboard/Authorize";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../contexts/appContext";

const AddClient = ({ setOpen, fetchChecker, setFetchChecker, filter }) => {
  const errorPopupRef = useRef(null);

  const context = useContext(Context);
  const navigate = useNavigate();

  const [name, setname] = useState("");
  const [location, setlocation] = useState("");
  const [error, setError] = useState(null);

  const AddClient = async (e) => {
    e.preventDefault();
    const auth = checkUser();
    if (auth === "success") {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/clients`,
          {
            method: "POST",
            body: JSON.stringify({
              name,
              location,
              category: filter,
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        const response = await res.json();
        if (response.success) {
          setOpen(false);
          setFetchChecker(!fetchChecker);
        } else {
          setError(response.message);
          errorPopupRef.current.openTrigger();
        }
      } catch (error) {
        setError(error.message);
        errorPopupRef.current.openTrigger();
        setTimeout(() => {
          setOpen(false);
          setFetchChecker(!fetchChecker);
        }, 5000);
      }
    } else if (auth === "expired") {
      context.setExpiredToken(true);
      navigate("/login");
    }
  };

  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        setOpen(false);
      }
    };

    window.addEventListener("keyup", close);

    return (_) => {
      window.removeEventListener("keyup", close);
    };
  }, [setOpen]);

  return (
    <AnimatePresence>
      {setOpen && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.3,
              ease: "easeInOut",
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.2,
            },
          }}
          className="fixed inset-0 z-10 h-screen w-screen bg-[#000000aa]"
        >
          <motion.div
            initial={{
              scale: 0,
            }}
            animate={{
              scale: 1,
            }}
            exit={{
              scale: 0,
              transition: {
                delay: 0.2,
              },
            }}
            className="fixed inset-0 m-auto h-[25rem] w-full rounded-md bg-white p-[0.2rem] xs:h-[30rem] xs:w-[23rem] ss:h-[30rem] ss:w-[27.5rem] sm:h-[30rem] sm:w-[40rem] sm:rounded-xl sm:p-[0.5rem]"
          >
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  delay: 0.3,
                },
              }}
              exit={{
                y: 50,
                opacity: 0,
                transition: {
                  duration: 0.15,
                },
              }}
              className="relative h-full"
            >
              <AiOutlineCloseCircle
                className="absolute top-0 right-0 z-10 cursor-pointer rounded-full bg-transparent p-px text-3xl transition-all duration-300 hover:text-red-500 xs:text-4xl ss:text-[2.5rem] md:p-0.5"
                onClick={() => setOpen(false)}
              />
              <form
                className="absolute inset-0 flex flex-col items-center justify-center rounded bg-[#e9ebf3]"
                onSubmit={AddClient}
              >
                <div className="mt-5 w-3/4">
                  <span className="py-10 text-sm font-semibold text-[#8f8f8f]">
                    Name
                  </span>
                  <input
                    placeholder="Name"
                    name="name"
                    type="text"
                    className="relative mx-auto w-full rounded bg-white py-2 px-5 text-black shadow outline-none"
                    onChange={(e) => setname(e.target.value)}
                  />
                </div>
                <div className="mt-5 w-3/4">
                  <span className="py-10 text-sm font-semibold text-[#8f8f8f]">
                    location
                  </span>
                  <input
                    placeholder="Location"
                    name="location"
                    type="text"
                    className="relative mx-auto w-full rounded bg-white py-2 px-5 text-black shadow outline-none"
                    onChange={(e) => setlocation(e.target.value)}
                  />
                </div>
                <div className="absolute right-3 bottom-3 flex items-center justify-between xs:right-5 xs:bottom-5">
                  <input
                    type="submit"
                    className="mr-1 cursor-pointer rounded-md border-0 bg-red-500 py-1 px-6 text-sm text-white outline-0 transition-all duration-300 hover:bg-red-400 xs:py-2 xs:px-6 sm:px-8 sm:text-lg"
                    value="Add"
                  />
                </div>
              </form>
            </motion.div>
          </motion.div>
          <ErrorPopup ref={errorPopupRef} message={error} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AddClient;
