import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { AiFillEdit } from "react-icons/ai";
import { EditService } from "./EditService";

const ServiceCard = (props) => {
  const { data, index, fetchChecker, setFetchChecker, loggedIn } = props;
  const [showDesc, setShowDesc] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  return (
    <div
      className={`relative mx-auto ${
        index % 2 ? "xl:mr-auto xl:ml-0" : "xl:ml-auto xl:mr-0"
      } flex h-96 w-full flex-col items-center justify-center overflow-x-hidden rounded bg-red-100 before:absolute before:inset-0 before:rounded before:bg-[#0005] ss:rounded-lg ss:before:rounded-lg sm:h-auto sm:w-5/6`}
      onMouseEnter={() => setShowDesc(true)}
      onMouseLeave={() => setShowDesc(false)}
    >
      <img
        src={`${process.env.REACT_APP_BACKEND_URL}/${data.image}`}
        alt={data.name}
        className="h-full w-full object-cover"
      />
      <AnimatePresence>
        {!loggedIn ? (
          !showDesc && (
            <motion.h1
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.5,
                  ease: "easeInOut",
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.2,
                  ease: "easeInOut",
                },
              }}
              className="absolute w-full bg-[#fff8] py-1 text-center text-xl font-bold text-red-500 underline backdrop-blur-[2px] xs:py-3 ss:no-underline sm:py-5 sm:text-3xl lg:text-5xl"
            >
              {data.name}
            </motion.h1>
          )
        ) : (
          <h1 className="absolute w-full bg-[#fff8] py-1 text-center text-xl font-bold text-red-500 backdrop-blur-[2px] xs:py-3 sm:py-5 sm:text-3xl lg:text-5xl">
            {data.name}
          </h1>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!loggedIn && showDesc && (
          <motion.div
            initial={{
              y: "-100%",
            }}
            animate={{
              y: 0,
              transition: {
                duration: 0.5,
                ease: "easeInOut",
              },
            }}
            exit={{
              y: "-100%",
              transition: {
                duration: 0.2,
                ease: "easeInOut",
              },
            }}
            className="absolute flex h-full w-full flex-col items-center justify-center bg-[#fffa] px-2 text-[#ef4444] backdrop-blur"
          >
            <p className="text-sm sm:text-base lg:text-lg">
              {data.description} <b>{data.highlight}</b>
            </p>
          </motion.div>
        )}
      </AnimatePresence>
      {loggedIn && (
        <div
          onClick={() => setOpenEdit(true)}
          className="absolute top-1 cursor-pointer text-3xl"
        >
          <AiFillEdit className="text-5xl text-white" />
        </div>
      )}
      {openEdit && (
        <EditService
          setopen={setOpenEdit}
          id={data._id}
          name={data.name}
          description={data.description}
          highlight={data.highlight}
          image={data.image}
          fetcher={fetchChecker}
          setFetcher={setFetchChecker}
        />
      )}
    </div>
  );
};

export default ServiceCard;
