import React, { useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ErrorPopup from "../Popup/AlertPopup/ErrorPopup";

export const EditService = ({
  setopen,
  name,
  id,
  description,
  highlight,
  image,
  fetcher,
  setFetcher,
}) => {
  const errorPopupRef = useRef(null);
  const [names, setname] = useState(name);
  const [descriptions, setdescription] = useState(description);
  const [highlights, sethighlight] = useState(highlight);
  const [imagee, setimage] = useState(image);
  const [error, setError] = useState(null);

  const editService = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("name", names);
    formdata.append("description", descriptions);
    formdata.append("highlight", highlights);
    formdata.append("folder", "services");
    formdata.append("image", imagee);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/services/${id}`,
        {
          method: "PUT",
          body: formdata,
          content: "multipart/form-data",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      const response = await res.json();
      if (response.success) {
        setopen(false);
        setFetcher(!fetcher);
      } else {
        setError(response.message);
        errorPopupRef.current.openTrigger();
      }
    } catch (error) {
      errorPopupRef.current.openTrigger();
      console.log(error);
    }
  };
  const changeImage = (e) => {
    if (e.target.files.length > 0) {
      const src = URL.createObjectURL(e.target.files[0]);
      const preview = document.getElementById("image");
      preview.src = src;
    }
  };

  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        setopen(false);
      }
    };

    window.addEventListener("keyup", close);

    return (_) => {
      window.removeEventListener("keyup", close);
    };
  }, [setopen]);

  return (
    <AnimatePresence>
      {setopen && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.3,
              ease: "easeInOut",
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.2,
            },
          }}
          className="fixed inset-0 z-10 h-screen w-screen bg-[#000000aa]"
        >
          <motion.div
            initial={{
              scale: 0,
            }}
            animate={{
              scale: 1,
            }}
            exit={{
              scale: 0,
              transition: {
                delay: 0.2,
              },
            }}
            className="fixed inset-0 m-auto h-5/6 w-full rounded-xl bg-white p-2 ss:h-[35rem] sm:h-[40rem] sm:h-[45rem] sm:w-[40rem]"
          >
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  delay: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.15,
                },
              }}
              className="relative h-full"
            >
              <AiOutlineCloseCircle
                className="absolute top-1 right-1 z-10 cursor-pointer rounded-full bg-transparent p-px text-3xl transition-all duration-300 hover:text-red-500 xs:text-4xl ss:text-[2.5rem] md:p-0.5"
                onClick={() => setopen(false)}
              />
              <div className="relative h-full w-full overflow-hidden rounded">
                <form
                  className="flex h-full flex-col items-center justify-center bg-[#e9ebf3]"
                  onSubmit={(e) => editService(e)}
                >
                  <img
                    id="image"
                    className="mx-auto h-1/4 rounded-lg object-cover sm:h-2/6 sm:w-3/4"
                    src={
                      image
                        ? `${process.env.REACT_APP_BACKEND_URL}/${image}`
                        : "./samuele-errico-piccarini-FMBWFDiVRPs-unsplash.jpg"
                    }
                    alt="services"
                  />
                  <input
                    type="file"
                    multiple={false}
                    className="my-2 w-full text-sm ss:my-5 ss:w-auto ss:text-base"
                    onChange={(e) => {
                      console.log(e.target.files[0]);
                      setimage(e.target.files[0]);
                      changeImage(e);
                    }}
                  />
                  <div className="mt-2 w-full px-3 ss:mt-5 ss:w-3/4 ss:px-0">
                    <span className="text-sm font-semibold text-[#8f8f8f]">
                      Name
                    </span>
                    <input
                      placeholder="name"
                      defaultValue={name}
                      name="name"
                      type="text"
                      className="relative mx-auto w-full rounded bg-white py-2 px-5 text-black shadow outline-none"
                      onChange={(e) => setname(e.target.value)}
                    />
                  </div>

                  <div className="mt-2 w-full px-3 ss:mt-5 ss:w-3/4 ss:px-0">
                    <span className="text-sm font-semibold text-[#8f8f8f]">
                      description
                    </span>
                    <input
                      placeholder="description"
                      defaultValue={description}
                      name="description"
                      type="text"
                      className="relative mx-auto w-full rounded bg-white py-2 px-5 text-black shadow outline-none"
                      onChange={(e) => setdescription(e.target.value)}
                    />
                  </div>

                  <div className="mt-2 w-full px-3 ss:mt-5 ss:w-3/4 ss:px-0">
                    <span className="text-sm font-semibold text-[#8f8f8f]">
                      highlight
                    </span>
                    <input
                      placeholder="highlight"
                      defaultValue={highlight}
                      name="highlight"
                      type="text"
                      className="relative mx-auto w-full rounded bg-white py-2 px-5 text-black shadow outline-none"
                      onChange={(e) => sethighlight(e.target.value)}
                    />
                  </div>

                  <div className="absolute right-3 bottom-3 flex items-center justify-between xs:right-5 xs:bottom-5">
                    <input
                      type="submit"
                      className="mr-1 cursor-pointer rounded-md border-0 bg-red-500 py-1 px-6 text-sm text-white outline-0 transition-all duration-300 hover:bg-red-400 xs:py-2 xs:px-6 sm:px-8 sm:text-lg"
                      value="Add"
                    />
                  </div>
                </form>
              </div>
            </motion.div>
          </motion.div>
          <ErrorPopup ref={errorPopupRef} message={error} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
