import React, { useState } from "react";
import LoginForm from "../../Components/LoginForm";
import { Context } from "../../contexts/appContext";
import { useContext } from "react";
import { useEffect } from "react";
import checkUser from "../../Dashboard/Authorize";

function Login() {
  const context = useContext(Context);

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const auth = checkUser();
    if (auth === "success") {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    context.setNavAppear(false);
    context.setFooterAppear(false);
  }, [context]);
  return <LoginForm loggedIn={loggedIn} setLoggedIn={setLoggedIn} />;
}

export default Login;
