import React, { useEffect } from "react";

const FilterProject = ({
  setActiveCategory,
  activeCategory,
  setFiltered,
  cards,
}) => {
  useEffect(() => {
    if (activeCategory === "All") {
      setFiltered(cards);
    } else {
      const filtered = cards.filter(
        (card) => card.service_id.name === activeCategory
      );
      setFiltered(filtered);
    }
  }, [activeCategory]);

  return (
    <div className="filter-container mb-2 mt-2 px-4 md:mb-4 md:mt-4 md:px-10 xl:px-20">
      <button
        className={`primary-font filter-button
          ${activeCategory === "All" ? "active" : ""}
        `}
        onClick={() => setActiveCategory("All")}
      >
        All
      </button>
      <button
        className={`primary-font filter-button
          ${activeCategory === "Decorative" ? "active" : ""}
        `}
        onClick={() => setActiveCategory("Decorative")}
      >
        Decorative
      </button>
      <button
        className={`primary-font filter-button
          ${activeCategory === "Solar Control" ? "active" : ""}
        `}
        onClick={() => setActiveCategory("Solar Control")}
      >
        Solar Control
      </button>
      <button
        className={`primary-font filter-button
          ${activeCategory === "Safety & Security" ? "active" : ""}
        `}
        onClick={() => setActiveCategory("Safety & Security")}
      >
        Safety & Security
      </button>
      <button
        className={`primary-font filter-button
          ${activeCategory === "Automotive" ? "active" : ""}
        `}
        onClick={() => setActiveCategory("Automotive")}
      >
        Automotive
      </button>
    </div>
  );
};

export default FilterProject;
