import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { AiOutlineCloseCircle } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../contexts/appContext";
import checkUser from "../../../Dashboard/Authorize";

const RestorePopup = forwardRef((props, ref) => {
  const context = useContext(Context);
  const navigate = useNavigate();

  const [restorePopup, setRestorePopup] = useState(false);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        setRestorePopup(false);
        props.setAlert({
          id: null,
          url: "",
          title: "",
          content: "",
        });
      }
    };
    window.addEventListener("keyup", close);
  }, []);

  useImperativeHandle(ref, () => {
    return {
      openTrigger: () => setRestorePopup(true),
      closeTrigger: () => setRestorePopup(false),
    };
  });

  const restorePayment = async () => {
    const auth = checkUser();
    if (auth === "success") {
      setClicked(true);
      try {
        const response = await fetch(`${props.alert.url}/${props.alert.id}`, {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        const result = await response.json();
        if (result.success) {
          setRestorePopup(false);
          props.setFetchChecker(!props.fetchChecker);
        } else {
          console.log(result);
        }
      } catch (error) {
        setClicked(false);
        console.log(error);
      }
    } else if (auth === "expired") {
      context.setExpiredToken(true);
      navigate("/login");
    }
  };
  return (
    <AnimatePresence>
      {restorePopup && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.5,
              ease: "easeInOut",
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.3,
            },
          }}
          className="fixed inset-0 z-10 h-screen w-screen bg-[#0002]"
        >
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
              transition: {
                delay: 0.3,
              },
            }}
            className="fixed top-[2%] left-[50%] h-32 w-64 translate-x-[-50%] rounded-lg bg-white p-2 shadow-[0_0px_10px_0px_#000a] xs:w-[22rem] ss:h-40 ss:w-96 sm:w-[35rem] sm:p-4"
          >
            <motion.div
              initial={{
                y: 20,
              }}
              animate={{
                y: 0,
              }}
              exit={{
                opacity: 0,
              }}
              className="relative flex h-full flex-col items-center"
            >
              <AiOutlineCloseCircle
                className="absolute top-0 right-0 z-10 cursor-pointer rounded-full bg-white p-px text-3xl transition-all duration-300 hover:text-red-500 xs:text-4xl ss:text-[2.5rem] md:p-0.5"
                onClick={() => {
                  setRestorePopup(false);
                  props.setAlert({
                    id: null,
                    url: "",
                    title: "",
                    content: "",
                  });
                }}
              />
              <p className="mb-2 self-start text-lg font-bold ss:text-xl sm:mb-4">
                {props.alert.title}
              </p>
              <p className="self-start text-sm ss:text-base sm:text-lg">
                {props.alert.content}
              </p>
              <div className="absolute bottom-0 w-fit">
                <button
                  disabled={clicked}
                  className={`${
                    clicked
                      ? "cursor-wait bg-red-100 text-red-300"
                      : "bg-red-500 text-white hover:border-red-400 hover:bg-white hover:text-red-500"
                  } mx-2 w-20 rounded border-2 border-transparent py-px text-xs outline-none transition-all duration-300 xs:py-0.5 xs:text-sm ss:text-base sm:w-28 sm:py-1`}
                  onClick={restorePayment}
                >
                  {clicked ? "Restoring" : "YES"}
                </button>
                <button
                  className="mx-2 w-20 rounded border-2 border-transparent bg-gray-500 py-px text-xs text-white outline-none transition-all duration-300 hover:border-gray-400 hover:bg-white hover:text-gray-500 xs:py-0.5 xs:text-sm ss:text-base sm:w-28 sm:py-1"
                  onClick={() => {
                    setRestorePopup(false);
                    props.setAlert({
                      id: null,
                      url: "",
                      title: "",
                      content: "",
                    });
                  }}
                >
                  CANCEL
                </button>
              </div>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
});

export default RestorePopup;
