import React from "react";
import herImg from "./service-hero.jpg";
const ServiceHeroImage = () => {
  return (
    <div
      className="projects-header-container flex h-[18rem] flex-col items-center justify-center bg-cover bg-fixed bg-bottom bg-no-repeat md:h-[28rem]"
      style={{
        backgroundImage: `linear-gradient(to bottom, #0009, #0009), url(${herImg})`,
      }}
    >
      <h1 className="primary-font mb-3 text-3xl font-bold text-white ss:text-5xl">
        OUR
        <span className="text-red-500"> SERVICES</span>
      </h1>
    </div>
  );
};

export default ServiceHeroImage;
