import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { AiOutlineCloseCircle } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../contexts/appContext";
import checkUser from "../../../Dashboard/Authorize";
import ErrorPopup from "../AlertPopup/ErrorPopup";

const EditProjectPopup = forwardRef((props, ref) => {
  const errorPopupRef = useRef(null);

  const context = useContext(Context);
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [service_id, setService_id] = useState("");
  const [service, setService] = useState("");
  const [images, setImages] = useState("");
  const [editProjectPopup, setEditProjectPopup] = useState(false);
  const [error, setError] = useState(null);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (props.flag) {
      props.setFlag(false);
      setService_id(props.project?.service_id._id);
      setService(props.project?.service_id.name);
      setTitle(props.project?.title);
      setImages(props.project.images);
    }
    const close = (e) => {
      if (e.key === "Escape") {
        setEditProjectPopup(false);
        setTitle("");
        setService("");
        setImages("");
        setError(null);
      }
    };
    window.addEventListener("keyup", close);
  }, [props]);
  useImperativeHandle(ref, () => {
    return {
      openTrigger: () => setEditProjectPopup(true),
      closeTrigger: () => setEditProjectPopup(false),
    };
  });

  const editProject = async (e) => {
    e.preventDefault();
    const auth = checkUser();
    if (auth === "success") {
      setClicked(true);
      const formData = new FormData();
      formData.append("title", title);
      formData.append("service_id", service_id);
      formData.append("folder", "projects");
      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/projects/${props.project._id}`,
          {
            method: "PUT",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: formData,
          }
        );
        const result = await response.json();
        setClicked(false);
        if (result.success) {
          setEditProjectPopup(false);
          props.setFetchChecker(!props.fetchChecker);
        } else {
          setError(result.message);
          errorPopupRef.current.openTrigger();
        }
      } catch (error) {
        setClicked(false);
        setError(error.message);
        errorPopupRef.current.openTrigger();
        setTimeout(() => {
          setEditProjectPopup(false);
          props.setFetchChecker(!props.fetchChecker);
        }, 5000);
      }
    } else if (auth === "expired") {
      context.setExpiredToken(true);
      navigate("/login");
    }
  };

  const setServiceID = (e) => {
    setService(e.target.value);
    let id = props.services.find(
      (service) => service.name === e.target.value
    )._id;
    setService_id(id);
  };

  return (
    <AnimatePresence>
      {editProjectPopup && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.3,
              ease: "easeInOut",
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.2,
            },
          }}
          className="fixed inset-0 z-10 h-screen w-screen bg-[#000000aa]"
        >
          <motion.div
            initial={{
              scale: 0,
            }}
            animate={{
              scale: 1,
            }}
            exit={{
              scale: 0,
              transition: {
                delay: 0.2,
              },
            }}
            className="fixed inset-0 m-auto h-[25rem] w-[17rem] rounded-md bg-white p-[0.2rem] xs:h-[30rem] xs:w-[23rem] ss:h-[30rem] ss:w-[27.5rem] sm:h-[30rem] sm:w-[40rem] sm:rounded-xl sm:p-[0.5rem]"
          >
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  delay: 0.3,
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.15,
                },
              }}
              className="popup-content relative h-full"
            >
              <AiOutlineCloseCircle
                className="absolute top-0 right-0 z-10 cursor-pointer rounded-full bg-white p-px text-3xl transition-all duration-300 hover:text-red-500 xs:text-4xl ss:text-[2.5rem] md:p-0.5"
                onClick={() => {
                  setEditProjectPopup(false);
                  setTitle("");
                  setService("");
                  setImages("");
                  setError(null);
                }}
              />
              <form
                className="flex h-full flex-col items-center justify-center"
                onSubmit={editProject}
              >
                <h2 className="absolute top-8 text-xl font-bold xs:text-2xl ss:text-3xl">
                  Edit Project
                </h2>
                <div className="my-2 flex w-full items-center justify-between xs:w-11/12 ss:w-4/5 sm:w-3/4">
                  <label className="text-sm font-bold ss:text-base">
                    Title
                  </label>
                  <input
                    className="w-4/5 resize-none rounded-md border-0 bg-white px-1 py-4 text-sm outline-0 xs:text-base ss:w-3/4 sm:w-3/5 sm:px-2"
                    type="text"
                    placeholder="Enter Title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </div>
                <div className="my-2 flex w-full items-center justify-between xs:w-11/12 ss:w-4/5 sm:w-3/4">
                  <label className="text-sm font-bold ss:text-base">
                    Service
                  </label>
                  <select
                    className="w-4/5 resize-none rounded-md border-0 bg-white px-1 py-4 text-sm outline-0 xs:text-base ss:w-3/4 sm:w-3/5 sm:px-2"
                    value={service}
                    onChange={setServiceID}
                  >
                    {props.services.map((service, index) => (
                      <option key={index}>{service.name}</option>
                    ))}
                  </select>
                </div>
                <div className="my-2 flex w-full items-center justify-between xs:w-11/12 ss:w-4/5 sm:w-3/4">
                  <label className="text-sm font-bold ss:text-base">
                    Images
                  </label>

                  <input
                    className="w-4/5 resize-none rounded-md border-0 bg-white px-1 py-4 text-sm outline-0 file:mr-2 file:cursor-pointer file:rounded-xl file:border-0 file:bg-blue-50 file:py-1.5 file:px-2 file:text-xs file:text-blue-900 file:transition-all file:duration-300 hover:file:bg-blue-100 xs:text-sm xs:file:mr-4 xs:file:px-4 xs:file:py-2 xs:file:text-sm ss:w-3/4 sm:w-3/5 sm:px-2"
                    type="file"
                    multiple
                    accept=".jpg, .png, .jpeg"
                    onChange={(e) => {
                      setImages(e.target.files);
                    }}
                  />
                </div>
                <div className="absolute bottom-0 right-0 flex items-center justify-between">
                  <input
                    type="button"
                    value="CANCEL"
                    className="mr-1 cursor-pointer rounded-md border-0 bg-gray-700 py-1 px-4 text-sm text-white outline-0 transition-all duration-300 hover:bg-gray-900 xs:px-6 sm:px-8 sm:text-lg"
                    onClick={() => {
                      setEditProjectPopup(false);
                      setTitle("");
                      setService("");
                      setImages("");
                      setError(null);
                    }}
                  />
                  <input
                    disabled={clicked}
                    type="submit"
                    value={clicked ? "EDITING PROJECT..." : "EDIT PROJECT"}
                    className={`${
                      clicked
                        ? "cursor-wait bg-blue-100 text-blue-400"
                        : "cursor-pointer bg-blue-400 text-white hover:bg-blue-500"
                    } rounded-md border-0 py-1 px-4 text-sm outline-0 transition-all duration-300 xs:px-6 sm:px-8 sm:text-lg`}
                  />
                </div>
              </form>
            </motion.div>
          </motion.div>
          <ErrorPopup ref={errorPopupRef} message={error} />
        </motion.div>
      )}
    </AnimatePresence>
  );
});

export default EditProjectPopup;
