import CarGif from "../assets/icons8-car.gif";
import HouseGif from "../assets/house.gif";
import SheildGif from "../assets/sheild.gif";
import { useNavigate } from "react-router-dom";
import Summer from "../assets/icons8-summer.gif";
const Card = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="primary-font mt-4 p-4 text-center">
        <h1 className="mb-4 text-xl font-bold text-red-600 ss:text-3xl sm:text-4xl">
          What we Offer
        </h1>
        <h1 className="text-2xl sm:text-3xl">Our Services</h1>
      </div>
      <div className="primary-font container mx-auto flex flex-col items-center justify-center gap-2 px-10 py-10 sm:grid sm:grid-cols-2 xl:grid-cols-4">
        {/* Card one */}
        <div
          onClick={() => navigate("/services")}
          className="my-10 max-w-sm rounded-lg border-2 bg-white py-1 px-6 shadow-lg transition-all hover:border-red-500  sm:h-[15rem]"
        >
          <div className="-mt-16 flex justify-center md:justify-end">
            <img className="h-20 w-20 object-cover p-1 " src={CarGif} alt="" />
          </div>
          <div>
            <h2 className="primary-font p-2 text-3xl font-semibold text-gray-800 ">
              <p className="w-fit cursor-default transition-all hover:text-red-500">
                Automotive
              </p>
            </h2>
            <p className="mt-2 pb-10 text-gray-600">
              LLumar automotive films are designed to enhance your vehicle’s
              appearance while protecting both you and your car from the
              damaging effects of the sun.
            </p>
          </div>
        </div>
        {/* Card Two */}
        <div
          onClick={() => navigate("/services")}
          className="my-10 max-w-sm rounded-lg border-2 bg-white py-4 px-6 shadow-lg hover:border-red-500 sm:h-[15rem]"
        >
          <div className="-mt-16 flex justify-center md:justify-end">
            <img className="h-20 w-20 object-cover  p-1 " src={Summer} alt="" />
          </div>
          <div className="hover:text-white">
            <h2 className="primary-font p-2 text-3xl font-semibold text-gray-800 ">
              <p className="w-fit cursor-default transition-all hover:text-red-500">
                Solar Control
              </p>
            </h2>
            <p className="mt-2 pb-10 text-gray-600">
              Solar glass treatment films filter out the worst of the sun’s heat
              and glare while letting light in.
            </p>
          </div>
        </div>
        {/* Card Three */}
        <div
          onClick={() => navigate("/services")}
          className="my-10 max-w-sm rounded-lg border-2 bg-white py-4 px-6           shadow-lg transition-all hover:border-red-500 sm:h-[15rem]"
        >
          <div className="-mt-16 flex justify-center md:justify-end">
            <img
              className="bg-bott h-20 w-20  object-cover p-1 "
              src={SheildGif}
              alt=""
            />
          </div>
          <div className="hover:text-white">
            <h2 className="primary-font p-2 text-3xl font-semibold text-gray-800 ">
              <p className="w-fit cursor-default transition-all hover:text-red-500">
                Safety & Security
              </p>
            </h2>
            <p className="mt-2 pb-10 text-gray-600">
              LLumar safety film offers NavLink shield of protection against the
              unexpected.
            </p>
          </div>
        </div>
        {/* Card Four */}
        <div
          onClick={() => navigate("/services")}
          className="my-10 max-w-sm rounded-lg border-2 bg-white py-4 px-6 shadow-lg transition-all hover:border-red-500 sm:h-[15rem]"
        >
          <div className="-mt-16 flex justify-center md:justify-end">
            <img
              className="h-20 w-20 bg-white  object-cover p-2"
              src={HouseGif}
              alt=""
            />
          </div>
          <div className="hover:text-white">
            <h2 className="primary-font p-2 text-3xl font-semibold text-gray-800 ">
              <p className="w-fit cursor-default transition-all hover:text-red-500">
                Decorative
              </p>
            </h2>
            <p className="mt-2 pb-10 text-gray-600">
              Add beauty and privacy to your home or office with LLumar
              decorative films.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
