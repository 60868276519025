import React, { useEffect, useState } from "react";
import { BiSearchAlt } from "react-icons/bi";

const SearchRecord = ({ search, setSearch, label }) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);

  return (
    <div className="crud-search-container relative mb-2 flex w-28 items-center justify-center border-b-[1px] border-gray-900 py-1 sm:w-36 md:mt-0 md:ml-5 md:w-fit md:py-1">
      <div className="search-icon flex items-center justify-center text-[#000a] transition-all duration-300">
        <label htmlFor="search">
          <BiSearchAlt />
        </label>
      </div>
      <div className="relative ml-1 md:ml-2">
        <p
          className="crud-search-input-title absolute left-[1%] z-[-10] translate-y-[-50%] text-sm text-[#000a] transition-all duration-300 md:text-lg"
          style={{ top: search ? "-30%" : "50%" }}
        >
          {label}
        </p>
        <input
          id="search"
          className="w-full border-0 bg-transparent p-1 text-sm outline-0 md:text-lg"
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          autoComplete="search"
        />
      </div>
    </div>
  );
};

export default SearchRecord;
