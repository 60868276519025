import React, { useContext, useEffect, useRef, useState } from "react";
import Tilt from "react-parallax-tilt";
import { motion, AnimatePresence } from "framer-motion";

import FilterProject from "../../Components/Projects/FilterProject";
import MoreDetailsPopup from "../../Components/Popup/MoreDetailsPopup";
import { Context } from "../../contexts/appContext";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";

import heroImg from "../../assets/projects-hero.jpg";

const Projects = () => {
  const context = useContext(Context);
  const moreDetailsRef = useRef(null);
  const [images, setImages] = useState(null);
  const [name, setName] = useState(null);
  const [filtered, setFiltered] = useState(null);
  const [cards, setCards] = useState(null);
  const [activeCategory, setActiveCategory] = useState("All");
  const [isLoading, setIsLoading] = useState(true);

  const fetchProjects = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/projects`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      if (result.success) {
        setFiltered(result.data);
        setCards(result.data);
        setIsLoading(false);
      } else {
        alert(result.message);
        console.log(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    context.setNavAppear(true);
    context.setFooterAppear(true);
    fetchProjects();
  }, [context]);
  return (
    <div className="projects-container">
      <div
        className="projects-header-container flex h-[18rem] flex-col items-center justify-center bg-cover bg-fixed bg-bottom bg-no-repeat md:h-[28rem]"
        style={{
          backgroundImage: `linear-gradient(to bottom, #0009, #0009), url(${heroImg})`,
        }}
      >
        <h1 className="primary-font mb-3 text-3xl font-bold text-white ss:text-5xl">
          OUR
          <span className="text-red-500"> PROJECTS</span>
        </h1>
      </div>
      {!isLoading ? (
        <>
          <FilterProject
            cards={cards}
            setFiltered={setFiltered}
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
          />
          <motion.div
            layout
            className="projects-list-container mb-5 flex flex-wrap items-center justify-center px-4 md:px-10 xl:px-20"
          >
            <AnimatePresence>
              {filtered.length > 0 ? (
                filtered.map(
                  (card, index) =>
                    !card.deleted && (
                      <motion.div
                        key={card._id}
                        layout
                        initial={{
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                        }}
                        exit={{
                          opacity: 0,
                        }}
                      >
                        <Tilt
                          tiltMaxAngleX={1}
                          tiltMaxAngleY={1}
                          perspective={1000}
                          scale={1.06}
                          className="project-card-container relative m-[0.5rem] flex w-[15rem] flex-col items-stretch justify-center truncate rounded shadow-lg transition-all duration-300 xs:w-[20rem] md:w-[25rem] lg:m-[1rem] lg:w-[30rem]"
                        >
                          <div
                            className="cursor-pointer"
                            onClick={(e) => {
                              setImages(card.images);
                              setName(card.title);
                              moreDetailsRef.current.openTrigger();
                            }}
                          >
                            <img
                              src={`${process.env.REACT_APP_BACKEND_URL}/${card.images[0]}`}
                              className="h-[16rem] w-full object-cover object-top md:h-[21rem] lg:h-[26rem]"
                              alt=""
                            />
                            <div className="project-card-content relative flex flex-col p-[0.5rem] lg:p-[1rem]">
                              <h2 className="project-card-title primary-font relative w-fit cursor-default text-sm xs:text-base">
                                {card.title}
                              </h2>
                              <div className="project-card-details-container mt-[0.5rem] flex items-center justify-between sm:mt-[1rem]">
                                {/* <button className="project-card-button primary-font radius w-fit cursor-pointer rounded-full border border-solid bg-red-500 py-2 px-2 text-xs text-white transition-all duration-300 hover:border-red-500 hover:bg-white hover:text-red-500 sm:py-2 sm:px-3 md:py-3 md:px-4">
                              More Details
                            </button>
                            <span className="project-card-type primary-font rounded-full border border-solid border-[#5353c4] py-0.5 px-1 text-xs text-[#5353c4] md:py-1.5 md:px-2">
                              {card.service_id.name}
                            </span> */}
                                <span className="project-card-type primary-font text-sm text-[#0006] sm:text-lg">
                                  {card.service_id.name}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Tilt>
                      </motion.div>
                    )
                )
              ) : (
                <p className="py-24 md:py-40 md:text-3xl">
                  No projects exist for this Category
                </p>
              )}
            </AnimatePresence>
          </motion.div>
        </>
      ) : (
        <LoadingSpinner name="Projects" />
      )}
      <MoreDetailsPopup images={images} name={name} ref={moreDetailsRef} />
    </div>
  );
};

export default Projects;
