import React, { useState, useEffect, useContext } from "react";
import emailjs from "@emailjs/browser";
import Image from "../../assets/new1.jpg";
import { Context } from "../../contexts/appContext";
import checkUser from "../../Dashboard/Authorize";

import { AiFillEdit } from "react-icons/ai";
import EditContact from "../../Components/Popup/ContactPop/EditContact";

const ContactUs = () => {
  const context = useContext(Context);

  const [loggedIn, setLoggedIn] = useState(false);
  const [result, showResult] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [contactUs, setContactUs] = useState(null);
  const [fetchChecker, setFetchChecker] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("gmail", "template", e.target, "09eGNrd_5gZl5tKEk").then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
    e.target.reset();
    showResult(true);
  };

  const fetchContactUs = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/contacts/632daf79e87aec3552b85949`
    );
    const result = await res.json();
    if (result.success) {
      setContactUs(result.data);
    } else {
      console.log(result.message);
    }
  };

  useEffect(() => {
    const auth = checkUser();
    if (auth === "success") {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    fetchContactUs();
    context.setNavAppear(true);
    context.setFooterAppear(true);
  }, [context, fetchChecker]);

  const Result = () => {
    return (
      <p className=" text-md absolute mt-[-7rem] ml-[6rem] text-center text-gray-600 md:mt-[-7rem] md:ml-[18rem] md:w-[19rem] lg:mt-[-7rem] lg:ml-[85rem] lg:mr-[5rem] lg:w-[19rem]">
        <div
          id="alert"
          className="mb-4 flex rounded-lg bg-green-100 p-4 dark:bg-green-200"
          role="alert"
        >
          <svg
            aria-hidden="true"
            className="h-5 w-5 flex-shrink-0 text-green-700 dark:text-green-800"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Info</span>
          <div className="ml-1.5 text-sm font-medium text-green-700 dark:text-green-800">
            Your message is successfully sent !
          </div>
          <button
            onClick={() => showResult(false)}
            type="button"
            className="-mx-1.5 -my-1.5 inline-flex h-8 w-8 rounded-lg bg-green-100 p-1.5 text-green-500 hover:bg-green-200 focus:ring-2 focus:ring-green-400 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300"
            data-dismiss-target="#alert-3"
            aria-label="Close"
          >
            <span className="sr-only">Close</span>
            <svg
              aria-hidden="true"
              className="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </p>
    );
  };

  return (
    <div>
      <header
        className="flex h-[18rem] w-full items-center justify-center bg-cover bg-fixed bg-center md:h-[28rem]"
        style={{
          backgroundImage: `linear-gradient(to bottom, #0009, #0009), url(${Image})`,
        }}
      >
        <h1 className="primary-font mb-3 text-3xl font-bold text-white ss:text-5xl">
          <span className="text-red-500">CONTACT </span>
          US
        </h1>
      </header>
      <div className="flex flex-wrap items-center justify-evenly py-10 xl:h-screen">
        <div className="relative m-5 flex h-5/6 w-[40rem] flex-col justify-center border-y-4 border-red-500 p-3 text-center sm:mx-0 sm:p-5">
          {loggedIn && (
            <div
              onClick={() => setOpenEdit(true)}
              className="absolute top-1 cursor-pointer text-3xl"
            >
              <AiFillEdit />
            </div>
          )}
          <div className="flex flex-col justify-center ss:flex-row ss:items-center">
            <div className="mt-5 w-full ss:mt-0 ss:w-1/2">
              <div className="my-2 flex items-center ss:my-5">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  className="h-8 w-8 rounded-full bg-red-100 p-0.5 text-red-500 sm:h-10 sm:w-10 sm:p-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                {contactUs?.address && (
                  <p className="ml-2 text-xs font-bold sm:text-base">
                    {contactUs?.address}
                  </p>
                )}
              </div>
              <div className="my-2 flex items-center ss:my-5">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  className="h-8 w-8 rounded-full bg-red-100 p-0.5 text-red-500 sm:h-10 sm:w-10 sm:p-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                {contactUs?.phone && (
                  <p className="ml-2 text-xs font-bold sm:text-base">
                    {contactUs?.phone}
                  </p>
                )}
              </div>

              <div className="my-2 flex items-center ss:my-5">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  className="h-8 w-8 rounded-full bg-red-100 p-0.5 text-red-500 sm:h-10 sm:w-10 sm:p-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                {contactUs?.email && (
                  <p className="ml-2 text-xs font-bold sm:text-base">
                    {contactUs?.email}
                  </p>
                )}
              </div>
            </div>
            <div className="w-full ss:w-1/2">
              <div className="my-2 flex items-center ss:my-5">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  className="h-8 w-8 rounded-full bg-red-100 p-0.5 text-red-500 sm:h-10 sm:w-10 sm:p-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <div>
                  {contactUs?.po_box && (
                    <p className="ml-2 text-xs font-bold sm:text-base">
                      {contactUs?.po_box}
                    </p>
                  )}
                </div>
              </div>
              <div className="my-2 flex items-center ss:my-5">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  className="h-8 w-8 rounded-full bg-red-100 p-0.5 text-red-500 sm:h-10 sm:w-10 sm:p-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                  />
                </svg>
                {contactUs?.fax && (
                  <p className="ml-2 text-xs font-bold sm:text-base">
                    {contactUs?.fax}
                  </p>
                )}
              </div>
              <div className="my-2 flex items-center ss:my-5">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  className="h-8 w-8 rounded-full bg-red-100 p-0.5 text-red-500 sm:h-10 sm:w-10 sm:p-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                  />
                </svg>
                {contactUs?.website && (
                  <p className="ml-2 text-xs font-bold sm:text-base">
                    {contactUs?.website}
                  </p>
                )}
              </div>
            </div>
          </div>
          <iframe
            title="Location"
            className="mt-5 h-60 w-full rounded border-2 border-red-200 ss:h-96 ss:border-4 sm:rounded-xl"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d303.8230954508988!2d35.64151915348687!3d33.95382632929938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f3f241a6adda9%3A0x1558ce57fc81f9c1!2sJonco%20ME%20Trading%20-%20LLumar%20Window%20Films!5e0!3m2!1sen!2slb!4v1664625828945!5m2!1sen!2slb"
            loading="lazy"
          ></iframe>
        </div>

        <form
          onSubmit={sendEmail}
          className="relative m-5 flex h-5/6 w-[40rem] flex-col justify-center border-y-4 border-red-500 p-3 sm:mx-0 sm:p-5 xl:my-0"
        >
          <div className="mb-5 flex flex-wrap">
            <div className="w-1/2 pr-2">
              <label className="text-xs font-bold text-gray-700 ss:text-sm">
                FIRST NAME
              </label>
              <input
                className="mt-1 w-full rounded border bg-gray-50 p-2 text-xs text-gray-500 outline-none ring-red-400 transition-all focus:bg-white focus:ring-1 ss:mt-2 ss:py-3 ss:px-4 ss:text-sm ss:focus:ring-2"
                type="text"
                name="first-name"
                placeholder="First Name"
                required
              />
            </div>
            <div className="w-1/2 pl-2">
              <label className="text-xs font-bold text-gray-700 ss:text-sm">
                LAST NAME
              </label>
              <input
                className="mt-1 w-full rounded border bg-gray-50 p-2 text-xs text-gray-500 outline-none ring-red-400 transition-all focus:bg-white focus:ring-1 ss:mt-2 ss:py-3 ss:px-4 ss:text-sm ss:focus:ring-2"
                type="text"
                name="last-name"
                placeholder="Last Name"
                required
              />
            </div>
          </div>
          <div className="-mx-3 mb-6 flex flex-wrap">
            <div className="w-full px-3">
              <label className="text-xs font-bold text-gray-700 ss:text-sm">
                E-MAIL
              </label>
              <input
                className="mt-1 w-full rounded border bg-gray-50 p-2 text-xs text-gray-500 outline-none ring-red-400 transition-all focus:bg-white focus:ring-1 ss:mt-2 ss:py-3 ss:px-4 ss:text-sm ss:focus:ring-2"
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                required
              />
            </div>
          </div>
          <div className="-mx-3 mb-6 flex flex-wrap">
            <div className="w-full px-3">
              <label className="text-xs font-bold text-gray-700 ss:text-sm">
                MESSAGE
              </label>
              <textarea
                className="mt-1 w-full resize-none rounded border bg-gray-50 p-2 text-xs text-gray-500 outline-none ring-red-400 transition-all focus:bg-white focus:ring-1 ss:mt-2 ss:py-3 ss:px-4 ss:text-sm ss:focus:ring-2"
                name="message"
                placeholder="Your Message"
                required
              ></textarea>
            </div>
          </div>
          <button
            className="mx-auto w-fit rounded-full bg-red-500 p-2 text-xs font-bold text-white ss:py-3 ss:px-4 ss:text-base"
            type="submit"
          >
            SEND MESSAGE
          </button>
        </form>
      </div>
      <div className="row">{result ? <Result /> : null}</div>
      {openEdit && (
        <EditContact
          contact={contactUs}
          setEditOpen={setOpenEdit}
          fetchChecker={fetchChecker}
          setFetchChecker={setFetchChecker}
        />
      )}
    </div>
  );
};

export default ContactUs;
