import React from "react";

const LoadingSpinner = ({ name }) => {
  return (
    <div className="loading-container flex h-[40vh] flex-col items-center justify-center md:h-[50vh]">
      <p className="loading-title text-red-500 xs:text-xl md:text-3xl">
        Loading {name}...
      </p>
      <div className="loading-spinner mt-8 h-16 w-16 rounded-full border-4 border-red-200 border-t-red-500 xs:h-20 xs:w-20 md:h-28 md:w-28"></div>
    </div>
  );
};

export default LoadingSpinner;
