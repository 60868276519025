import React from "react";
import { motion, AnimatePresence } from "framer-motion";

const UnavailableData = (props) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            delay: 0.3,
          },
        }}
        exit={{
          y: 50,
          opacity: 0,
          transition: {
            duration: 0.15,
          },
        }}
        className="unavailable-container"
      >
        <p className="unavailable-title">No {props.title} Available</p>
        <p className="unavailable-description">
          Try & Add {props.data} to appear here
        </p>
      </motion.div>
    </AnimatePresence>
  );
};

export default UnavailableData;
