import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Context } from "../contexts/appContext";

const NotFound = () => {
  const context = useContext(Context);
  useEffect(() => {
    context.setNavAppear(true);
    context.setFooterAppear(true);
  });
  return (
    <div className="flex h-[50vh] flex-col items-center justify-center">
      <h1 className="mb-4 text-2xl font-semibold">Page Not Found</h1>
      <label>
        Navigate to{" "}
        <Link
          to="/"
          className="text-red-500 transition-all hover:font-semibold"
        >
          Home
        </Link>
      </label>
    </div>
  );
};

export default NotFound;
