import React, { useContext, useEffect } from "react";
import JoncoLogo from "../assets/Logo.png";
import LoginImage from "../assets/LoginImage.jpg";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Context } from "../contexts/appContext";
import alreadyLogin from "../assets/Images/already-loggedIn.png";

function LoginForm(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const context = useContext(Context);
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      context.setExpiredToken(false);
    }, 3000);
  }, [context, props.loggedIn]);

  const dashBoardLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );
      const result = await response.json();
      if (result.success) {
        localStorage.setItem("token", result.token);
        localStorage.setItem("id", result.data.id);
        localStorage.setItem("role", result.data.role);
        setTimeout(() => {
          navigate("/dashboard/projects");
        }, 200);
      } else {
        setCredentials(result.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const loggingOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    props.setLoggedIn(false);
  };

  return (
    <>
      <section
        style={{ backgroundImage: `url(${LoginImage})` }}
        className="overlay h-screen bg-cover bg-left-bottom  bg-no-repeat"
      >
        {!props.loggedIn ? (
          <div className="flex h-screen flex-col items-center justify-center px-2 sm:px-0">
            <NavLink
              to="#"
              className="mb-6 hidden items-center text-2xl font-semibold text-white dark:text-white"
            >
              JONCO
              <img
                className="hidden w-36 rounded-md p-2 dark:hidden  dark:bg-white"
                src={JoncoLogo}
                alt="logo"
              />
            </NavLink>
            <div className="w-full rounded-lg bg-white shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md md:mt-0 xl:p-0">
              <div className="space-y-4 p-6 sm:p-8 md:space-y-6">
                <p className="font-semibold text-red-500">{credentials}</p>
                {context.expiredToken && (
                  <p className="rounded bg-red-100 py-2 px-5 text-sm font-semibold text-red-500">
                    Token Expired
                  </p>
                )}
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
                  Sign in to your account
                </h1>
                <form
                  className="space-y-4 md:space-y-6"
                  onSubmit={(e) => dashBoardLogin(e)}
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Your email
                    </label>
                    <input
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      type="email"
                      name="email"
                      id="email"
                      className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                      placeholder="name@company.com"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Password
                    </label>
                    <input
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      type="password"
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                      required
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id="remember"
                          aria-describedby="remember"
                          type="checkbox"
                          className="focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 h-4 w-4 rounded border border-gray-300 bg-gray-50 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="remember"
                          className="text-gray-500 dark:text-gray-300"
                        >
                          Remember Me
                        </label>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="hover:bg-primary-700 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 w-full rounded-lg bg-red-500 px-5 py-2.5 text-center text-sm font-medium text-white focus:outline-none focus:ring-4"
                  >
                    Sign in
                  </button>
                  <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                    Login to your Jonco Dashboard to edit your website.
                  </p>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div className="absolute top-1/2 left-1/2 flex translate-x-[-50%] translate-y-[-50%] items-center justify-center">
            <img
              src={alreadyLogin}
              alt="already-login"
              className="already-login-img h-[10rem] w-[5rem] object-cover object-center ss:h-[20rem] ss:w-[10rem] sm:h-[30rem] sm:w-[10rem]"
            />
            <div className="already-login h-[10rem] w-[20rem] bg-gray-800 text-sm text-white ss:h-[20rem] ss:w-[30rem] ss:text-lg sm:h-[30rem] sm:w-[40rem] sm:text-xl">
              <h1 className="already-login--title mb-1 bg-white py-1 text-center text-lg text-gray-800 ss:mb-10 ss:py-2 ss:text-xl sm:mb-20 sm:py-4 sm:text-3xl">
                Already Logged In
              </h1>
              <button
                className="ml-2 cursor-pointer rounded-lg border-0 bg-transparent p-2 text-sm text-red-500 hover:text-white hover:underline ss:ml-10 ss:text-lg sm:ml-20 sm:text-xl"
                onClick={loggingOut}
              >
                Logout
              </button>
              <span>Or</span>
              <div className="ml-2 p-2 ss:ml-10 sm:ml-20">
                <span>Navigate to </span>
                <button
                  className="cursor-pointer border-0 bg-transparent text-sm text-green-500 hover:text-white hover:underline ss:text-lg sm:text-xl"
                  onClick={() => {
                    navigate("/dashboard/projects");
                    props.setLoggedIn(false);
                  }}
                >
                  Dashboard
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default LoginForm;
