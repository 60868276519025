import CarImage from "../assets/car_cover.jpg";

const About = () => {
  return (
    <div id="about" className="bg-gray-100">
      <div className="primary-font p-10 text-center">
        <h1 className="mb-4 text-xl font-bold text-red-600 ss:text-3xl sm:text-4xl">
          Information
        </h1>
        <h1 className="text-2xl sm:text-3xl">About Us</h1>
      </div>
      <div className="primary-font">
        <div className="container mx-auto max-w-7xl">
          <div className="container mx-auto">
            <div>
              <section className="body-font text-gray-600">
                <div className="container mx-auto flex flex-col p-5">
                  <div className="mx-auto">
                    <div className="h-96 overflow-hidden rounded-lg">
                      <img
                        className="h-full w-full object-cover object-center"
                        src={CarImage}
                        alt="content"
                      />
                    </div>
                    <div className="mt-10 flex flex-col items-end justify-center sm:flex-row">
                      <div className="text-center sm:w-1/3 sm:py-8 sm:pr-8">
                        <div className="inline-flex h-20 w-20 items-center justify-center rounded-full bg-gray-200 text-gray-400">
                          <svg
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            className="h-10 w-10"
                            viewBox="0 0 24 24"
                          >
                            <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                          </svg>
                        </div>
                        <div className="flex flex-col items-center justify-center text-center">
                          <h2 className="title-font primary-font mt-4  text-lg font-extrabold">
                            JONCO M.E. Trading S.A.R.L.
                          </h2>
                          <div className="mt-2 mb-4 h-1 w-12 rounded bg-red-500"></div>
                          <p className="text-md p-1 leading-relaxed">
                            August 2005, Jonco was certified as the Exclusive
                            Distributor for LLumar in Lebanon and Syria. –
                            CPFilms Inc., in Lebanon and Syria.LLumar safety
                            film has been installed at numerous Embassies,
                            banks, hotels, and Government buildings worldwide.
                          </p>
                        </div>
                      </div>
                      <div className="mt-4 border-t border-gray-200 pt-4 text-center sm:mt-0 sm:w-2/3 sm:border-l sm:border-t-0 sm:py-8 sm:pl-8 sm:text-left">
                        {/* <p className="mb-4 text-lg leading-relaxed">
                          <span className="font-semibold text-red-500">
                            Jonco
                          </span>{" "}
                          was established in 1980 and was dealing initially with
                          all contracts works related to Aluminum and glazing;
                          at the beginning of 1994, Jonco introduced in Lebanon,
                          installation of Solar & Safety control films, this
                          new, revolutionary product has many advantages to
                          offer.
                        </p> */}
                        <p className="text-lg leading-relaxed">
                          <span className="font-semibold text-red-500">
                            LLumar – CPFilms Inc.
                          </span>{" "}
                          is the largest producer of window film in the world
                          providing high-quality films for the commercial,
                          residential and automotive marketplaces. All of the
                          films we offer feature ultra-thin, advanced technology
                          adding protection, comfort and style to all buildings
                          and vehicles.
                          <span className="block">
                            <br />
                            What makes us different?
                            <br />
                            As a brand that’s manufactured by Eastman
                            Performance Films, our products are conceived,
                            engineered, and perfected with a deep understanding
                            of film technology. This expert knowledge allows us
                            to be a trusted resource for consumers in search of
                            film solutions around the world.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
