import React, { useContext, useEffect, useState } from "react";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";
import ServiceCard from "../../Components/Services/ServiceCard";
import ServiceHeroImage from "../../Components/Services/ServiceHeroImage";
import { Context } from "../../contexts/appContext";
import checkUser from "../../Dashboard/Authorize";

const Services = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [services, setServices] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchChecker, setFetchChecker] = useState(false);

  const getAllServices = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/services`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      setServices(result.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  const context = useContext(Context);

  useEffect(() => {
    setIsLoading(true);
    const auth = checkUser();
    if (auth === "success") {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    getAllServices();
    context.setNavAppear(true);
    context.setFooterAppear(true);
  }, [context, fetchChecker]);
  return (
    <>
      <ServiceHeroImage />
      {!isLoading ? (
        <div className="grid min-h-screen grid-cols-1 gap-5 py-10 px-5 ss:px-10 xl:grid-cols-2">
          {services.map((service, index) => (
            <ServiceCard
              key={service._id}
              data={service}
              index={index}
              loggedIn={loggedIn}
              fetchChecker={fetchChecker}
              setFetchChecker={setFetchChecker}
            />
          ))}
        </div>
      ) : (
        <LoadingSpinner name="Services" />
      )}
    </>
  );
};

export default Services;
