import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import ContactUs from "./Pages/Contact/Contact";
import Projects from "./Pages/Projects/Projects";
import Clients from "./Pages/Clients/Clients";
import Footer from "./Components/Footer";
import NavbarPages from "./Components/NavbarPages";
import { useContext } from "react";
import { Context } from "./contexts/appContext";
import Login from "./Pages/Login/Login";
import ProjectsCrud from "./Dashboard/ProjectsCrud";
import NotFound from "./Pages/NotFound";
import ScrollToTop from "./Components/ScrollToTop";
import Services from "./Pages/Services/Services";
function App() {
  const context = useContext(Context);
  return (
    <Router>
      <ScrollToTop />
      {context.navAppear && <NavbarPages />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/contact" element={<ContactUs />} />

        {/* DASHBOARD ROUTES */}
        <Route path="/login" element={<Login />} />
        {localStorage.getItem("token") && (
          <>
            <Route path="/dashboard/projects" element={<ProjectsCrud />} />
          </>
        )}
        <Route path="*" element={<NotFound />} />
      </Routes>
      {context.footerAppear && <Footer />}
    </Router>
  );
}

export default App;
