import React, { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import checkUser from "../../../Dashboard/Authorize";
import { Context } from "../../../contexts/appContext";
import ErrorPopup from "../AlertPopup/ErrorPopup";

const EditContact = (props) => {
  const errorPopupRef = useRef(null);

  const [inputs, setInputs] = useState({
    phone: props.contact.phone,
    address: props.contact.address,
    fax: props.contact.fax,
    website: props.contact.website,
    po_box: props.contact.po_box,
    email: props.contact.email,
  });
  const [error, setError] = useState(null);

  const context = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        props.setEditOpen(false);
      }
    };
    window.addEventListener("keyup", close);
  }, [props]);

  const changeInput = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const editContact = async (e) => {
    e.preventDefault();
    const auth = checkUser();
    if (auth === "success") {
      try {
        console.log(inputs);
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/contacts/${props.contact._id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(inputs),
          }
        );
        const result = await response.json();
        if (result.success) {
          props.setEditOpen(false);
          props.setFetchChecker(!props.fetchChecker);
        } else {
          setError(result.message);
          errorPopupRef.current.openTrigger();
        }
      } catch (error) {
        setError(error.message);
        errorPopupRef.current.openTrigger();
        setTimeout(() => {
          props.setEditOpen(false);
          props.setFetchChecker(!props.fetchChecker);
        }, 5000);
      }
    } else if (auth === "expired") {
      context.setExpiredToken(true);
      navigate("/login");
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.3,
            ease: "easeInOut",
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            delay: 0.2,
          },
        }}
        className="fixed inset-0 z-10 h-screen w-screen bg-[#000000aa]"
      >
        <motion.div
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
          }}
          exit={{
            scale: 0,
            transition: {
              delay: 0.2,
            },
          }}
          className="fixed inset-0 m-auto h-[35rem] w-full rounded-md bg-white p-2 xs:h-[30rem] xs:w-[23rem] ss:w-[27.5rem] sm:h-[35rem] sm:w-[40rem] sm:rounded-xl"
        >
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                delay: 0.3,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.15,
              },
            }}
            className="popup-content relative h-full"
          >
            <AiOutlineCloseCircle
              className="absolute top-0 right-0 z-10 cursor-pointer rounded-full bg-transparent p-px text-3xl transition-all duration-300 hover:text-red-500 xs:text-4xl ss:text-[2.5rem] md:p-0.5"
              onClick={() => {
                props.setEditOpen(false);
              }}
            />
            <form
              className="flex h-full flex-col items-center justify-center rounded bg-gray-200 p-2 xs:p-0"
              onSubmit={editContact}
            >
              <h2 className="absolute top-4 text-sm font-bold ss:text-xl sm:top-8 sm:text-2xl md:text-3xl">
                Add Project
              </h2>
              <div className="my-2 flex w-full flex-col justify-between xs:w-11/12 xs:flex-row xs:items-center ss:w-4/5 sm:w-3/4">
                <label className="text-sm font-bold ss:text-base">
                  Address
                </label>
                <input
                  className="resize-none rounded-t-md border-0 border-b-2 border-b-gray-400 bg-white px-1 py-2 text-sm outline-0 xs:w-4/5 xs:text-base ss:w-3/4 sm:w-3/5 sm:px-2"
                  type="text"
                  name="address"
                  placeholder="Enter Address"
                  value={inputs.address}
                  onChange={changeInput}
                />
              </div>
              <div className="my-2 flex w-full flex-col justify-between xs:w-11/12 xs:flex-row xs:items-center ss:w-4/5 sm:w-3/4">
                <label className="text-sm font-bold ss:text-base">Email</label>
                <input
                  className="resize-none rounded-t-md border-0 border-b-2 border-b-gray-400 bg-white px-1 py-2 text-sm outline-0 xs:w-4/5 xs:text-base ss:w-3/4 sm:w-3/5 sm:px-2"
                  type="text"
                  name="email"
                  placeholder="Enter Email"
                  value={inputs.email}
                  onChange={changeInput}
                />
              </div>
              <div className="my-2 flex w-full flex-col justify-between xs:w-11/12 xs:flex-row xs:items-center ss:w-4/5 sm:w-3/4">
                <label className="text-sm font-bold ss:text-base">PO BOX</label>
                <input
                  className="resize-none rounded-t-md border-0 border-b-2 border-b-gray-400 bg-white px-1 py-2 text-sm outline-0 xs:w-4/5 xs:text-base ss:w-3/4 sm:w-3/5 sm:px-2"
                  type="text"
                  name="po_box"
                  placeholder="Enter PO BOX"
                  value={inputs.po_box}
                  onChange={changeInput}
                />
              </div>
              <div className="my-2 flex w-full flex-col justify-between xs:w-11/12 xs:flex-row xs:items-center ss:w-4/5 sm:w-3/4">
                <label className="text-sm font-bold ss:text-base">
                  Website
                </label>
                <input
                  className="resize-none rounded-t-md border-0 border-b-2 border-b-gray-400 bg-white px-1 py-2 text-sm outline-0 xs:w-4/5 xs:text-base ss:w-3/4 sm:w-3/5 sm:px-2"
                  type="text"
                  name="website"
                  placeholder="Enter Website Name"
                  value={inputs.website}
                  onChange={changeInput}
                />
              </div>
              <div className="my-2 flex w-full flex-col justify-between xs:w-11/12 xs:flex-row xs:items-center ss:w-4/5 sm:w-3/4">
                <label className="text-sm font-bold ss:text-base">Fax</label>
                <input
                  className="resize-none rounded-t-md border-0 border-b-2 border-b-gray-400 bg-white px-1 py-2 text-sm outline-0 xs:w-4/5 xs:text-base ss:w-3/4 sm:w-3/5 sm:px-2"
                  type="text"
                  name="fax"
                  placeholder="Enter Fax"
                  value={inputs.fax}
                  onChange={changeInput}
                />
              </div>
              <div className="my-2 flex w-full flex-col justify-between xs:w-11/12 xs:flex-row xs:items-center ss:w-4/5 sm:w-3/4">
                <label className="text-sm font-bold ss:text-base">Phone</label>
                <input
                  className="resize-none rounded-t-md border-0 border-b-2 border-b-gray-400 bg-white px-1 py-2 text-sm outline-0 xs:w-4/5 xs:text-base ss:w-3/4 sm:w-3/5 sm:px-2"
                  type="text"
                  name="phone"
                  placeholder="Enter Phone"
                  value={inputs.phone}
                  onChange={changeInput}
                />
              </div>
              <div className="absolute bottom-0 right-0 flex items-center justify-between">
                <input
                  type="button"
                  value="CANCEL"
                  className="mr-1 cursor-pointer rounded-md border-0 bg-gray-700 py-1 px-4 text-sm text-white outline-0 transition-all duration-300 hover:bg-gray-900 xs:px-6 sm:px-8 sm:text-lg"
                  onClick={() => {
                    props.setEditOpen(false);
                  }}
                />
                <input
                  type="submit"
                  value="EDIT CONTACT"
                  className="cursor-pointer rounded-md border-0 bg-blue-400 py-1 px-4 text-sm text-white outline-0 transition-all duration-300 hover:bg-blue-500 xs:px-6 sm:px-8 sm:text-lg"
                />
              </div>
            </form>
          </motion.div>
        </motion.div>
        <ErrorPopup ref={errorPopupRef} message={error} />
      </motion.div>
    </AnimatePresence>
  );
};

export default EditContact;
