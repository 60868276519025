import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const MoreDetailsPopup = forwardRef((props, ref) => {
  const [moreProjectDetailsPopup, setMoreProjectDetailsPopup] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        setMoreProjectDetailsPopup(false);
      }
    };
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("keyup", close);

    return (_) => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("keyup", close);
    };
  }, []);
  useImperativeHandle(ref, () => {
    return {
      openTrigger: () => setMoreProjectDetailsPopup(true),
      closeTrigger: () => setMoreProjectDetailsPopup(false),
    };
  });
  return (
    <AnimatePresence>
      {moreProjectDetailsPopup && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.3,
              ease: "easeInOut",
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.2,
            },
          }}
          className="fixed inset-0 z-10 h-screen w-screen bg-[#000000aa]"
        >
          <motion.div
            initial={{
              scale: 0,
            }}
            animate={{
              scale: 1,
            }}
            exit={{
              scale: 0,
              transition: {
                delay: 0.2,
              },
            }}
            className="fixed inset-0 m-auto h-[17.5rem] w-[16rem] rounded-md bg-white p-[0.2rem] xs:h-[23.5rem] xs:w-[22rem] ss:h-[28rem] ss:w-[26.5rem] sm:h-[37rem] sm:w-[35.5rem] sm:rounded-xl sm:p-[0.5rem] md:h-[47rem] md:w-[45.5rem] xl:h-[55rem] xl:w-[53.5rem]"
          >
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  delay: 0.3,
                },
              }}
              exit={{
                y: 50,
                opacity: 0,
                transition: {
                  duration: 0.15,
                },
              }}
              className="popup-content relative h-full"
            >
              <AiOutlineCloseCircle
                className="popup-close-icon absolute top-0	right-0 z-10 cursor-pointer bg-white p-px text-3xl transition-all duration-300 hover:text-red-500 xs:text-4xl ss:text-[2.5rem] md:p-0.5"
                onClick={() => setMoreProjectDetailsPopup(false)}
              />
              {props.images.length > 1 ? (
                <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  navigation={width > 1024}
                  pagination={{
                    dynamicBullets: true,
                    clickable: true,
                  }}
                  autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                >
                  {props.images.map((image, index) => (
                    <SwiperSlide
                      key={index}
                      className="more-details-images-container"
                    >
                      <img
                        src={`${process.env.REACT_APP_BACKEND_URL}/${image}`}
                        alt={props.name}
                        className="h-[16rem] h-[15.5rem] w-full rounded-md object-cover object-top xs:h-[21.5rem] ss:h-[25.5rem] sm:h-[34rem] md:h-[44rem] xl:h-[52rem]"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}/${props.images[0]}`}
                  alt={props.name}
                  className={`${
                    props.name.includes("Kuwait")
                      ? "object-center"
                      : "object-top"
                  } h-[16rem] h-[15.5rem] w-full rounded-md object-cover xs:h-[21.5rem] ss:h-[25.5rem] sm:h-[34rem] md:h-[44rem] xl:h-[52rem]`}
                />
              )}
              <h3 className="more-details-project-name secondary-font relative w-fit cursor-default px-px text-[14px] ss:text-base">
                {props.name}
              </h3>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
});

export default MoreDetailsPopup;
