import { useState } from "react";
import { createContext } from "react";

export const Context = createContext();

const ContextProvider = (props) => {
  const [navAppear, setNavAppear] = useState(false);
  const [footerAppear, setFooterAppear] = useState(true);
  const [expiredToken, setExpiredToken] = useState(false);

  return (
    <Context.Provider
      value={{
        navAppear,
        setNavAppear,
        footerAppear,
        setFooterAppear,
        expiredToken,
        setExpiredToken,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default ContextProvider;
