import JoncoLogo from "../assets/Logo.png";
import { useState } from "react";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import HeroImage3 from "../assets/herooverlay2.png";
import { HashLink } from "react-router-hash-link";
import JONCO from "../assets/JONCO WHITE 1.png";

const Navbar = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const navigate = useNavigate();

  const openCloseMenu = () => {
    setMenuToggle(!menuToggle);
  };

  const loggingOut = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    navigate("/");
  };

  return (
    <div
      style={{ backgroundImage: `url(${HeroImage3})` }}
      className="overlay h-[100vh] bg-cover bg-left-bottom  bg-no-repeat "
    >
      <nav className="primary-font text-lg subpixel-antialiased">
        <div className="mx-auto max-w-7xl">
          <div className="mx-auto flex items-center justify-between py-4 px-4">
            <div>
              <img
                className="w-36 rounded-md  p-2"
                src={JONCO}
                alt="Company Logo"
              />
            </div>
            {/* Primary Nav */}
            <div className="hidden md:block ">
              {!localStorage.getItem("token") && (
                <NavLink
                  className="primary-font px-3 text-xl font-bold text-white decoration-red-500 decoration-2 underline-offset-2 focus:underline md:px-6   lg:text-2xl"
                  to="/"
                >
                  Home
                </NavLink>
              )}
              {localStorage.getItem("token") &&
                localStorage.getItem("role") === "super_admin" && (
                  <NavLink
                    className="primary-font px-3 text-xl font-bold text-white decoration-red-500 decoration-2 underline-offset-2 focus:underline md:px-6   lg:text-2xl"
                    to="/admins"
                  >
                    Admins
                  </NavLink>
                )}
              <NavLink
                className="primary-font px-3 font-bold text-white decoration-red-500 decoration-2 underline-offset-2 focus:underline md:px-6  lg:text-2xl"
                to={localStorage.getItem("token") ? "/services" : "/services"}
              >
                Services
              </NavLink>
              <NavLink
                className="primary-font px-3 font-bold text-white decoration-red-500 decoration-2 underline-offset-2 focus:underline md:px-6   lg:text-2xl"
                to={
                  localStorage.getItem("token")
                    ? "/dashboard/projects"
                    : "/projects"
                }
              >
                Projects
              </NavLink>
              <NavLink
                className="primary-font px-3 font-bold text-white decoration-red-500 decoration-2 underline-offset-2 focus:underline md:px-6   lg:text-2xl"
                to="/clients"
              >
                Clients
              </NavLink>
            </div>
            {/* Secondary Nav */}
            <div className="flex">
              <NavLink
                className="hidden w-28 rounded-full bg-red-500 px-2 py-2 text-sm text-white shadow-sm shadow-red-800 transition-all hover:bg-red-400 md:flex md:items-center md:justify-center lg:w-36 lg:px-0 lg:text-lg"
                to="/contact"
              >
                Contact us
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="ml-1 inline h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </NavLink>
              {localStorage.getItem("token") && (
                <button
                  onClick={loggingOut}
                  className="ml-2 hidden text-sm text-white md:block lg:ml-5 lg:text-base"
                >
                  Logout
                </button>
              )}
            </div>
            {/* Mobile Nav */}
            <div className="flex items-center justify-center md:hidden">
              <button onClick={openCloseMenu}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-10 w-10 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className={menuToggle ? "md:hidden " : "hidden md:hidden"}>
          <div className="flex flex-col justify-center border bg-white p-3  ">
            {!localStorage.getItem("token") && (
              <NavLink
                to="/"
                className="text-md inline-block w-full  rounded px-2 py-2 text-center hover:bg-red-400 hover:text-white"
              >
                Home
              </NavLink>
            )}
            {localStorage.getItem("token") &&
              localStorage.getItem("role") === "super_admin" && (
                <NavLink
                  to="/admins"
                  className="text-md inline-block w-full  rounded px-2 py-2 text-center hover:bg-red-400 hover:text-white"
                >
                  Admins
                </NavLink>
              )}
            <NavLink
              to={localStorage.getItem("token") ? "/services" : "/services"}
              className="text-md inline-block w-full  rounded px-2 py-2 text-center hover:bg-red-400 hover:text-white"
            >
              Services
            </NavLink>
            <NavLink
              to={
                localStorage.getItem("token")
                  ? "/dashboard/projects"
                  : "/projects"
              }
              className="text-md inline-block w-full  rounded px-2 py-2 text-center hover:bg-red-400 hover:text-white"
            >
              Projects
            </NavLink>
            <NavLink
              to="/clients"
              className="text-md inline-block w-full  rounded px-2 py-2 text-center hover:bg-red-400 hover:text-white"
            >
              Clients
            </NavLink>
            <NavLink
              to="/contact"
              className="text-md inline-block w-full  rounded px-2 py-2 text-center hover:bg-red-400 hover:text-white"
            >
              Contact us
            </NavLink>
            {localStorage.getItem("token") && (
              <button
                onClick={loggingOut}
                className="text-md inline-block w-full rounded px-2 py-2 text-center hover:bg-red-400 hover:text-white"
              >
                Logout
              </button>
            )}
          </div>
        </div>
      </nav>
      {/* The new section */}
      <section className="body-font mx-auto  max-w-7xl text-white md:mt-16">
        <div className="mx-auto mt-10 flex flex-col items-center px-2 py-5 pt-10 sm:px-5 sm:py-16 md:flex-row md:py-24">
          <div className="mb-16 flex flex-col items-center text-center md:mb-0 md:w-2/3 md:items-start md:pr-16 md:text-left lg:flex-grow lg:pr-24">
            <h1 className="primary-font mb-5 text-3xl font-bold sm:mb-10  sm:text-4xl">
              <span className="rounded-md bg-red-600 p-1">LLumar</span>{" "}
              Exclusive Distributor
            </h1>
            <p className="primary-font mb-8 max-w-[500px] text-2xl font-light leading-relaxed">
              Jonco introduced the installation of Solar & Safety control films
              in Lebanon. This new revolutionary product is used to improve
              glass performance in homes, offices and cars.
            </p>
            <div className="flex justify-center lg:pl-24">
              <HashLink
                smooth
                to="#about"
                className="primary-font inline-flex rounded-full border-0 bg-none py-3 px-16 text-lg text-white ring-2 ring-red-500 hover:bg-red-600 focus:outline-none"
              >
                Learn More
              </HashLink>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Navbar;
