import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../contexts/appContext";
import LoadingSpinner from "../Components/Loading/LoadingSpinner";
import SearchRecord from "../Components/SearchRecord";
import edit from "../assets/Icons/edit.png";
import remove from "../assets/Icons/delete.png";
import restore from "../assets/Icons/restore.png";
import UnavailableData from "../Components/EmptyData/UnavailableData";
import AddProjectPopup from "../Components/Popup/ProjectsPopup/AddProjectPopup";
import EditProjectPopup from "../Components/Popup/ProjectsPopup/EditProjectPopup";
import RemovePopup from "../Components/Popup/AlertPopup/RemovePopup";
import MoreDetailsPopup from "../Components/Popup/MoreDetailsPopup";
import checkUser from "./Authorize";
import RestorePopup from "../Components/Popup/AlertPopup/RestorePopup";

const ProjectsCrud = () => {
  const moreDetailsRef = useRef(null);
  const addProjectRef = useRef(null);
  const editProjectRef = useRef(null);
  const removeProjectRef = useRef(null);
  const restoreProjectRef = useRef(null);

  const [services, setServices] = useState(null);
  const [projects, setProjects] = useState(null);
  const [project, setProject] = useState(null);
  const [editProject, setEditProject] = useState(null);
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [fetchChecker, setFetchChecker] = useState(false);

  const [alert, setAlert] = useState({
    id: null,
    url: "",
    title: "",
    content: "",
  });

  const navigate = useNavigate();
  const context = useContext(Context);

  const fetchProjects = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/projects`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      if (result.success) {
        setProjects(result.data);
      } else {
        window.alert(result.message);
        console.log(result);
      }
      const services = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/services`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const res = await services.json();
      if (res.success) {
        setServices(res.data);
        setIsLoading(false);
      } else {
        window.alert(result.message);
        console.log(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const auth = checkUser();
    if (auth === "success") {
      setIsLoading(true);
      context.setNavAppear(true);
      context.setFooterAppear(false);
      fetchProjects();
    } else {
      navigate("/");
    }
  }, [context, navigate, fetchChecker]);
  return !isLoading ? (
    <div className="py-2 px-5 ss:px-10 ss:py-5 md:py-10 md:px-20">
      <div className="mt-3 flex justify-between ss:mt-2 md:mt-0">
        <SearchRecord
          setSearch={setSearch}
          search={search}
          label="Project Search"
        />
        <button
          className="mb-2 cursor-pointer rounded-xl border-2 border-transparent bg-transparent py-0.5 px-2 text-sm text-black transition-all duration-300 hover:border-blue-300 hover:text-blue-500 ss:text-lg md:py-1 md:px-5 md:text-xl"
          onClick={() => {
            addProjectRef.current.openTrigger();
            setAddFlag(true);
          }}
        >
          Add Project
        </button>
      </div>
      <div className="mt-2 flex justify-between bg-gray-900 p-2 text-xs font-bold text-white ss:py-2 ss:px-4 ss:text-sm md:py-4 md:px-6 md:text-xl">
        <p className="w-20 text-center">Name</p>
        <div className="flex justify-between">
          <p className="w-10 text-center ss:w-14 md:w-20">Restore</p>
          <p className="w-10 text-center ss:w-14 md:w-20">Edit</p>
          <p className="w-10 text-center ss:w-14 md:w-20">Delete</p>
        </div>
      </div>
      {projects.length !== 0 ? (
        <div className="project-crud">
          {projects.map(
            (project, index) =>
              project.title.includes(search) && (
                <div
                  key={index}
                  className="admin-records my-2 flex cursor-default items-center justify-between bg-red-50 p-2 text-xs text-gray-900 shadow-[0_0px_10px_-4px_#000a] transition-all duration-300 hover:bg-red-400 hover:text-white ss:px-4 ss:text-sm md:px-6 md:text-xl"
                  onClick={(e) => {
                    if (e.target.className.includes("admin-records")) {
                      setProject(project);
                      moreDetailsRef.current.openTrigger();
                    }
                  }}
                >
                  {project.title}
                  <div className="flex justify-between">
                    {project.deleted ? (
                      <div className="flex w-10 justify-center ss:w-14 md:w-20">
                        <img
                          src={restore}
                          className="edit-icon w-6 cursor-pointer transition-all duration-300 md:w-10"
                          alt="restore Project"
                          onClick={() => {
                            setAlert({
                              id: project._id,
                              url: `${process.env.REACT_APP_BACKEND_URL}/api/projects/restore`,
                              title: "Restore Project",
                              content:
                                "Are you sure you want to restore this project?",
                            });
                            restoreProjectRef.current.openTrigger();
                          }}
                        />
                      </div>
                    ) : (
                      <div className="flex w-10 justify-center ss:w-14 md:w-20"></div>
                    )}
                    {!project.deleted ? (
                      <div className="flex w-10 justify-center ss:w-14 md:w-20">
                        <img
                          src={edit}
                          className="edit-icon w-6 cursor-pointer transition-all duration-300 md:w-10"
                          alt="Edit Project"
                          onClick={() => {
                            setEditProject(project);
                            editProjectRef.current.openTrigger();
                            setEditFlag(true);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="flex w-10 justify-center ss:w-14 md:w-20"></div>
                    )}
                    {!project.deleted ? (
                      <div className="flex w-10 justify-center ss:w-14 md:w-20">
                        <img
                          src={remove}
                          className="edit-icon w-6 cursor-pointer transition-all duration-300 md:w-10"
                          alt="delete Project"
                          onClick={() => {
                            setAlert({
                              id: project._id,
                              url: `${process.env.REACT_APP_BACKEND_URL}/api/projects`,
                              title: "Remove Project",
                              content:
                                "Are you sure you want to remove this project?",
                            });
                            removeProjectRef.current.openTrigger();
                          }}
                        />
                      </div>
                    ) : (
                      <div className="flex w-10 justify-center ss:w-14 md:w-20"></div>
                    )}
                  </div>
                </div>
              )
          )}
        </div>
      ) : (
        <UnavailableData title="Projects" data="Project" />
      )}
      <MoreDetailsPopup
        images={project?.images}
        name={project?.title}
        ref={moreDetailsRef}
      />
      <AddProjectPopup
        ref={addProjectRef}
        services={services}
        flag={addFlag}
        setFlag={setAddFlag}
        fetchChecker={fetchChecker}
        setFetchChecker={setFetchChecker}
      />
      <EditProjectPopup
        ref={editProjectRef}
        services={services}
        project={editProject}
        flag={editFlag}
        setFlag={setEditFlag}
        fetchChecker={fetchChecker}
        setFetchChecker={setFetchChecker}
      />
      <RemovePopup
        ref={removeProjectRef}
        alert={alert}
        setAlert={setAlert}
        fetchChecker={fetchChecker}
        setFetchChecker={setFetchChecker}
      />
      <RestorePopup
        ref={restoreProjectRef}
        alert={alert}
        setAlert={setAlert}
        fetchChecker={fetchChecker}
        setFetchChecker={setFetchChecker}
      />
    </div>
  ) : (
    <LoadingSpinner name="Projects" />
  );
};

export default ProjectsCrud;
