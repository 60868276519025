import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../contexts/appContext";
import CategoryCard from "./CategoryCard";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";
import Image from "../../assets/test6.png";

const Clients = () => {
  const context = useContext(Context);

  const [clients, setClients] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchChecker, setFetchChecker] = useState(false);

  const fetchClients = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/clients`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resJson = await res.json();
      setClients(resJson.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    context.setNavAppear(true);
    context.setFooterAppear(true);
    fetchClients();
  }, [context, fetchChecker]);

  return (
    <div className="ClientContainer">
      <header
        className="flex h-[18rem] w-full items-center justify-center bg-cover bg-fixed sm:bg-center md:h-[28rem] md:bg-top"
        style={{ backgroundImage: `url(${Image})` }}
      >
        <h1 className="primary-font mb-3 text-3xl font-bold text-white ss:text-5xl">
          OUR
          <span className="text-red-500"> CLIENTS</span>
        </h1>
      </header>
      {!isLoading ? (
        clients.length > 0 ? (
          <div className="mx-auto mt-10 block w-[80%] lg:flex lg:flex-wrap lg:justify-between">
            <CategoryCard
              filter="International Organizations"
              clients={clients}
              fetchChecker={fetchChecker}
              setFetchChecker={setFetchChecker}
            />
            <CategoryCard
              filter="Embassies & Ministries"
              clients={clients}
              fetchChecker={fetchChecker}
              setFetchChecker={setFetchChecker}
            />
            <CategoryCard
              filter="Literature Centers"
              clients={clients}
              fetchChecker={fetchChecker}
              setFetchChecker={setFetchChecker}
            />
            <CategoryCard
              filter="Education Centers"
              clients={clients}
              fetchChecker={fetchChecker}
              setFetchChecker={setFetchChecker}
            />
            <CategoryCard
              filter="Banks"
              clients={clients}
              fetchChecker={fetchChecker}
              setFetchChecker={setFetchChecker}
            />
            <CategoryCard
              filter="Restaurants"
              clients={clients}
              fetchChecker={fetchChecker}
              setFetchChecker={setFetchChecker}
            />
            <CategoryCard
              filter="Shop Centers"
              clients={clients}
              fetchChecker={fetchChecker}
              setFetchChecker={setFetchChecker}
            />
            <CategoryCard
              filter="Glazing & Aluminium Companies"
              clients={clients}
              fetchChecker={fetchChecker}
              setFetchChecker={setFetchChecker}
            />
            <CategoryCard
              filter="Hospitals & Clinics"
              clients={clients}
              fetchChecker={fetchChecker}
              setFetchChecker={setFetchChecker}
            />
            <CategoryCard
              filter="Constructions"
              clients={clients}
              fetchChecker={fetchChecker}
              setFetchChecker={setFetchChecker}
            />
            <CategoryCard
              filter="Hotels"
              clients={clients}
              fetchChecker={fetchChecker}
              setFetchChecker={setFetchChecker}
            />
            <CategoryCard
              filter="Radio-TV"
              clients={clients}
              fetchChecker={fetchChecker}
              setFetchChecker={setFetchChecker}
            />
            <CategoryCard
              filter="Other Companies"
              clients={clients}
              fetchChecker={fetchChecker}
              setFetchChecker={setFetchChecker}
            />
          </div>
        ) : (
          <p className="py-24 md:py-40 md:text-3xl">
            No Clients exist for this Category
          </p>
        )
      ) : (
        <LoadingSpinner name="Clients" />
      )}
    </div>
  );
};

export default Clients;
