import React, { useEffect } from "react";
import Card from "../../Components/Card";
import About from "../../Components/About";
import Navbar from "../../Components/Navbar";
import { useContext } from "react";
import { Context } from "../../contexts/appContext";

const Home = () => {
  const context = useContext(Context);

  useEffect(() => {
    context.setNavAppear(false);
    context.setFooterAppear(true);
  }, [context]);
  return (
    <div>
      <Navbar />
      <About />
      <Card />
    </div>
  );
};

export default Home;
