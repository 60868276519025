import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { BiErrorAlt } from "react-icons/bi";
import { motion, AnimatePresence } from "framer-motion";

const ErrorPopup = forwardRef((props, ref) => {
  const [errorPopup, setErrorPopup] = useState(false);
  useEffect(() => {
    if (errorPopup) {
      setTimeout(() => setErrorPopup(false), 5000);
    }
  }, [errorPopup]);

  useImperativeHandle(ref, () => {
    return {
      openTrigger: () => setErrorPopup(true),
      closeTrigger: () => setErrorPopup(false),
    };
  });
  return (
    <AnimatePresence>
      {errorPopup && (
        <motion.div
          initial={{
            opacity: 0,
            x: -1000,
          }}
          animate={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.5,
              ease: "easeInOut",
            },
          }}
          exit={{
            opacity: 0,
            x: -1000,
            transition: {
              duration: 0.5,
              ease: "easeInOut",
            },
          }}
          className="error-popup-container absolute top-[1%] left-[1%] flex items-center justify-center rounded-lg bg-red-100 py-2 px-4 shadow-[0_0px_10px_0px_#000a]"
        >
          <BiErrorAlt className="error-popup-icon text-3xl text-red-500" />
          <p className="error-popup-message ml-2 text-xl font-bold text-red-500">
            {props.message}
          </p>
        </motion.div>
      )}
    </AnimatePresence>
  );
});

export default ErrorPopup;
