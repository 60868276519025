import React, { useEffect, useRef, useState } from "react";
import { AiFillEdit, AiFillPlusCircle } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { MdOutlineRestore } from "react-icons/md";
import AddClient from "../../Components/Popup/ClientsPopup/ClientAdd";
import EditClient from "../../Components/Popup/ClientsPopup/ClientEdit";
import checkUser from "../../Dashboard/Authorize";
import RestorePopup from "../../Components/Popup/AlertPopup/RestorePopup";
import RemovePopup from "../../Components/Popup/AlertPopup/RemovePopup";

function CategoryCard({ filter, clients, fetchChecker, setFetchChecker }) {
  const removeProjectRef = useRef(null);
  const restoreProjectRef = useRef(null);

  const [loggedIn, setLoggedIn] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [client, setClient] = useState(null);
  const [alert, setAlert] = useState({
    id: null,
    url: "",
    title: "",
    content: "",
  });
  useEffect(() => {
    const auth = checkUser();
    if (auth === "success") {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);
  return (
    <div>
      <div className="mb-3 w-fit cursor-default border-b-2 border-b-transparent text-sm font-bold transition-all duration-300 hover:border-b-2 hover:border-red-400 xs:text-lg sm:text-xl lg:text-2xl">
        {filter}
      </div>
      <div className="pb-[3rem] lg:w-[40rem]">
        {clients.length > 0 &&
          clients.map(
            (client) =>
              client.category === filter &&
              (loggedIn || (!loggedIn && !client.deleted)) && (
                <div
                  key={client._id}
                  className="my-2 flex justify-between sm:items-center"
                >
                  <ul key={client._id} className="flex flex-wrap">
                    <li className="inline text-sm sm:text-lg lg:text-xl">
                      {client.name}
                    </li>
                    {client.location && (
                      <li className="text-sm text-red-600 sm:text-lg lg:text-xl">
                        {" - "}
                        {client.location}
                      </li>
                    )}
                  </ul>
                  {loggedIn &&
                    (!client.deleted ? (
                      <div className="ml-2 flex">
                        <AiFillEdit
                          onClick={(e) => {
                            setClient(client);
                            setEditOpen(true);
                          }}
                          className="cursor-pointer text-lg text-green-500 sm:text-xl"
                        />
                        <FaTrash
                          onClick={() => {
                            setAlert({
                              id: client._id,
                              url: `${process.env.REACT_APP_BACKEND_URL}/api/clients`,
                              title: "Remove Client",
                              content:
                                "Are you sure you want to remove this client?",
                            });
                            removeProjectRef.current.openTrigger();
                          }}
                          className="cursor-pointer text-lg text-red-500 sm:text-xl"
                        />
                      </div>
                    ) : (
                      <div className="ml-2 flex">
                        <MdOutlineRestore
                          onClick={() => {
                            setAlert({
                              id: client._id,
                              url: `${process.env.REACT_APP_BACKEND_URL}/api/clients/restore`,
                              title: "Restore Client",
                              content:
                                "Are you sure you want to restore this client?",
                            });
                            restoreProjectRef.current.openTrigger();
                          }}
                          className="cursor-pointer text-xl text-red-500 sm:text-2xl"
                        />
                      </div>
                    ))}
                </div>
              )
          )}
        {loggedIn && (
          <AiFillPlusCircle
            onClick={(e) => setAddOpen(true)}
            className="cursor-pointer text-3xl text-blue-900"
          />
        )}
      </div>
      {editOpen && (
        <EditClient
          client={client}
          setOpen={setEditOpen}
          fetchChecker={fetchChecker}
          setFetchChecker={setFetchChecker}
        />
      )}
      {addOpen && (
        <AddClient
          setOpen={setAddOpen}
          filter={filter}
          fetchChecker={fetchChecker}
          setFetchChecker={setFetchChecker}
        />
      )}

      <RemovePopup
        ref={removeProjectRef}
        alert={alert}
        setAlert={setAlert}
        fetchChecker={fetchChecker}
        setFetchChecker={setFetchChecker}
      />
      <RestorePopup
        ref={restoreProjectRef}
        alert={alert}
        setAlert={setAlert}
        fetchChecker={fetchChecker}
        setFetchChecker={setFetchChecker}
      />
    </div>
  );
}

export default CategoryCard;
